<template>
  <div class="header container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <h1 style="margin-top:48px;">
          <router-link :to="{name: 'Home'}" class="text-reset text-decoration-none d-block">
            <img :src="logo" alt="Craftspeople" width="300" class="img-fluid"/>
          </router-link>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import CraftspeopleLogo from '@/assets/logo.png'

export default {
  name: 'Header',
  props: {
    text: String
  },
  data: function() {
    return {
      logo: CraftspeopleLogo
    }
  }
}
</script>

<style scoped>
h1 {
  font-family: Poppins;
  font-size: 4rem;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 3rem;
  }
}

.header {
  border-top: 12px solid #2A0088;
}
</style>
