<template>
<div class="container">
  <div class="row" style="margin:48px 0 48px 0;">
    <div class="col-10 offset-1 text-center">
      <p class="intro-text font-italic">
        We build software with open source technologies on the cloud.
      </p>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'BigTextBlock'
}
</script>
<style scoped>
h2 {
  font-family: Poppins;
}
.intro-text {
  font-family: Poppins;
  font-size: 1.5rem;
  line-height: 59px;
  font-weight: 800;
}
</style>
