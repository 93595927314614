<template>
<div class="container-fluid mt-5">
  <ContactUsForAMeeting title="Is it a match?" content="Do you think we can help you with our expertise? Contact us for a coffee or virtual meeting!"
              background-color="#2A0088"
              text-color="white" button-text="Get in touch" button-color="white"/>
</div>
</template>
<script>
import ContactUsForAMeeting from "@/components/ContactUsForAMeeting";

export default {
  name: 'ContactUs',
  components: {ContactUsForAMeeting}
}
</script>
<style scoped>
h3, h5 {
  font-family: Poppins;
  font-size: 1.75rem;
}

p {
  font-family: Roboto;
}

h6 {
  font-size: 1.25rem;
}
</style>
