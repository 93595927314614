<template>
    <router-view/>
</template>

<style scoped>
h3, h5 {
  font-family: Poppins;
  font-size: 1.75rem;
}

p {
  font-family: Poppins;
}

h6 {
  font-size: 1.25rem;
}
</style>
<script>
export default {
}
</script>
