<template>
  <Header/>
  <Navbar/>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="text-center mb-5">Our values</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="value-box">
          <h2 class="text-center text-md-left">Technical Excellence</h2>
          <p class="text-md-left text-center">
            We constantly challenge the status quo with insights we gather from conferences we attend and masterclasses, and courses we took.
            <br/> We sharpen our workflow and tools to be at our best with all this knowledge.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="value-box">
          <h2 class="text-center text-md-left">Collaboration</h2>
          <p class="text-md-left text-center">
            We try to achieve technical excellence by collaborating with our customers to understand their problems to solve them. 
            <br/>
            We share the knowledge within the team and help them be at their best.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="value-box">
          <h2 class="text-center text-md-left">Inclusive &amp; Diverse</h2>
          <p class="text-md-left text-center">
            We believe that everyone is different and that our differences will make us stronger.
          </p>
        </div>
      </div>
    </div>
  </div>
  <ContactUsForAMeeting title="Is it a match?"
                        content="Do you think we can help you with our expertise? Contact us for a coffee or virtual meeting!"
                        background-color="#2A0088"
                        text-color="white" button-text="Get in touch" button-color="white"/>
</template>
<script>
import Header from "@/components/Header"
import Navbar from "@/components/Navbar"
import ContactUsForAMeeting from "@/components/ContactUsForAMeeting";

export default {
  name: 'ValuesPage',
  components: {ContactUsForAMeeting, Header, Navbar}
}
</script>
<style scoped>
.container {
  margin-top: 48px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Poppins;
}

h2 {
  color: black;
  font-weight: bold;
  font-size: 1.5rem;
}

p, li {
  font-family: Roboto;
  line-height: 30px;
  font-size: 16px;
  margin-top: 12px;
}

.value-box {
  color: black;
  padding: 20px;
  margin-bottom: 2rem;;
}
</style>
