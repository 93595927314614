<template>
  <Header/>
  <Navbar/>
  <BigTextBlock/>
  <HowWeHelpYou/>
  <ContactUs/>
</template>
<script>
import Header from "@/components/Header";
import HowWeHelpYou from "@/components/HowWeHelpYou"
import ContactUs from "@/components/ContactUs"
import BigTextBlock from "@/components/BigTextBlock";
import Navbar from "@/components/Navbar"

export default {
  name: 'HomePage',
  components: {
    BigTextBlock,
    Header,
    ContactUs,
    HowWeHelpYou,
    Navbar
  }
}
</script>
<style scoped>
h3, h4, h5 {
  font-family: Poppins;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.50rem
}

h5 {
  font-size: 1.35rem;
}

p {
  font-family: Roboto;
  line-height: 30px;
  font-size: 1.25rem;
}
</style>
