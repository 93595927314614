<template>
  <Header/>
  <Navbar/>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="jumbotron text-left">
          <p class="lead">
            At <strong>Crafts</strong><i>People</i>, we're passionate about solving problems by creating well-built
            software.<br/>
            We love to write testable clean code, are eager to share our knowledge with fellow software creators. <br/>
          </p>
          <hr class="my-4">
          <p class="lead">
            We're looking for people as passionate as us about well-built software and Cloud Native solutions.
          </p>
        </div>
        <h1 class="text-center mb-5">Open positions</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="mb-3">
          <h2>Software Engineer</h2>
          <span class="text-muted">Remote, Belgium</span>
        </div>
      </div>
    </div>
  </div>
  <ContactUsForAMeeting title="Are you the person we're looking for?"
                        content="Send us your resume and why you think you're the right fit. We're looking forward to meet you!"
                        background-color="#2A0088"
                        text-color="white" button-text="Apply Now" button-color="white"/>
</template>
<script>
import Header from "@/components/Header"
import Navbar from "@/components/Navbar"
import ContactUsForAMeeting from "@/components/ContactUsForAMeeting";

export default {
  name: 'JobsPage',
  components: {ContactUsForAMeeting, Header, Navbar}
}
</script>
<style scoped>
.container {
  margin-top: 48px;
}

.jumbotron {
  background:#EB0029;
  color:white;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Poppins;
}

h2 {
  color: black;
  font-weight: bold;
  font-size: 1.5rem;
}

p, li {
  font-family: Roboto;
  line-height: 30px;
  font-size: 16px;
  margin-top: 12px;
}
</style>
