<template>
  <div class="container mb-1">
    <div class="row">
      <div class="col-12 text-center mb-3">
        <h5>Services</h5>
      </div>
    </div>
    <div class="row">
      <Card header="Cloud Native Solutions"
            content="We're big fans of AWS with expertise in architecting and building cost-effective, scalable Cloud Native solutions on AWS."/>
      <Card header="Modernize Legacy"
            content="We know that your legacy application still works and keeps the shop open. But to grow, your application needs to be refactored. We know how to approach legacy applications."/>
      <Card header="Greenfield"
            content="We're passionate about building well-built software.
            Together with your team we co-create high-quality software. "/>

      <div class="col-12 text-center">
        <img :src="cert.url" v-for="cert in this.certifications" v-bind:key="cert" :width="cert.width" :height="cert.height" class="mr-2 mt-3" :alt="cert.name"/>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/components/Card"
import AwsCertifiedSolutionsArchitectAssociate from '@/assets/certifications/aws-solutions-architect-associate-2020.png'
import AwsCertifiedDeveloperAssociate from '@/assets/certifications/aws-developer-associate-2020.png';
import SensioLabsCertifiedSymfonyDeveloper from '@/assets/certifications/sensiolabs-symfony-certification.png';
import ZendCertifiedEngineerImage from '@/assets/certifications/zend-certified-engineer.png'

export default {
  name: 'HowWeHelpYou',
  components: {Card},
  data: function () {
    return {
      certifications: [
        {
          name: 'AWS Certified Solutions Architect - Associate',
          url: AwsCertifiedSolutionsArchitectAssociate,
          height: 120,
          width: 120,
        },
        {
          name: 'AWS Certified Developer - Associate',
          url: AwsCertifiedDeveloperAssociate,
          height: 120,
          width: 120,
        },
        {
          name: 'SensioLabs Certified Symfony Developer',
          url: SensioLabsCertifiedSymfonyDeveloper,
          height: 120,
          width: 120,
        },
        {
          name: 'Zend Certified Engineer',
          url: ZendCertifiedEngineerImage,
          height: 100,
          width: 100,
        }
      ]
    }
  }
}
</script>
<style scoped>
h3, h5 {
  font-family: Poppins;
  font-size: 1.75rem;
}

p {
  font-family: Roboto;
}

h6 {
  font-size: 1.25rem;
}
</style>
