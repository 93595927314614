<template>
<nav>
  <div class="navbar-nav text-center text-uppercase mt-5">
    <ul class="list-inline">
      <router-link to="/" class="ml-5 ml-0 text-reset text-uppercase">Home</router-link>
      <router-link to="/values" class="ml-5 text-reset text-uppercase">Values</router-link>
      <router-link to="/jobs" class="ml-5 text-uppercase" style="color:#1055cc !important;">We're Hiring (2)</router-link>
    </ul>
  </div>
</nav>
</template>

<script>
export default {
  name: 'Navbar',
  props: {}
}
</script>

<style scoped>
a {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  color: black;
}
</style>
