<template>
  <div class="col-12 col-md-6">
    <div class="card rounded-0 border-0">
      <div class="card-body text-center text-md-left">
        <h5 class="card-title header">{{ header }}</h5>
        <p class="card-text">{{ content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    header: String,
    content: String,
  }
}
</script>

<style scoped>
.header {
  font-family: Poppins;
  font-weight: bold;
}

.card-text {
  font-family: Roboto;
  line-height: 30px;
}
</style>
