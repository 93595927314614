<template>
<div class="container">
  <div class="jumbotron" v-bind:style="'background-color:' + this.backgroundColor">
      <div class="row">
        <div class="col-12 col-md-9">
          <h2 class="text-center text-md-left" v-bind:style="'color: '+this.textColor">{{ title }}</h2>
          <p class="content text-center text-md-left" v-bind:style="'color:'+this.textColor">{{ content }}</p>
        </div>
        <div class="col-12 col-md-3 text-center text-sm-left">
          <a class="btn btn-lg btn-primary" v-bind:style="'background: '+this.buttonColor" href="mailto:info@craftspeople.io">{{ buttonText }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUsForAMeeting',
  props: {
    content: String,
    backgroundColor: String,
    buttonColor: String,
    textColor: String,
    buttonText: String,
    title: String,
  }
}
</script>

<style scoped>
.jumbotron {
  min-height: 200px;
  border-radius: 0;
}

h2 {
  font-family: Poppins;
}

.content {
  font-family: Roboto;
  font-size: 1.1rem;
}

a {
  border-radius: 0;
  border:0;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  color:#32006F;
}

a:hover{
  color:#32006F;
}
</style>
