import {createRouter, createWebHashHistory} from 'vue-router'
import HomePage from "@/views/HomePage";
import ValuesPage from "@/views/ValuesPage";
import JobsPage from "@/views/JobsPage";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
        meta: {
            title: 'CraftsPeople - Software Crafters'
        }
    },
    {
        path: '/values',
        name: 'Values',
        component: ValuesPage,
        meta: {
           title: 'CraftsPeople - Values, what\'s driving us?',
        }
    },
    {
        path: '/jobs',
        name: 'Jobs',
        component: JobsPage,
        meta: {
            title: 'CraftsPeople - Open positions'
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.afterEach(() => {
    window.scrollTo(0, 0);
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;

    next();
});

export default router
